import { BehaviorSubject } from "rxjs";
import ApiClient from "../../../shared/api/ApiClient";
import { SearchStoreMasterParams, StoreMasterParams, StoreType } from "../models/store";

const SEARCH_STORE_MASTER = "/v2/stores/search";
const UPLOAD_STORE_MASTER = "v2/stores/master/upload";
const ENABLE_SEND_NOTI = "/v2/stores/notification/enable";
const DISABLE_SEND_NOTI = "/v2/stores/notification/disable";
const ENABLE_SEND_NOTI_ALL = "/v2/stores/notification/enable-all";
const DISABLE_SEND_NOTI_ALL = "/v2/stores/notification/disable-all";
const DOWNLOAD_STORE_MASTER = "/v2/stores/download";
const TOGGLE_CHECKING_GEOFENCE = "v2/stores/:store_number/checking-geofence";

const searchStoreMaster = (params: SearchStoreMasterParams): Promise<any> =>
  ApiClient.post(SEARCH_STORE_MASTER, params).then((res) => {
    return res.data;
  });

const uploadStoreMaster = async (originFileObj: File): Promise<any> => {
  const formData = new FormData();
  formData.append("file", originFileObj);
  const res = await ApiClient.post(UPLOAD_STORE_MASTER, formData, { timeout: 3600000 });
  return res.data;
};

const enableSendNotification = (params: string[]): Promise<any> =>
  ApiClient.post(ENABLE_SEND_NOTI, params).then((res) => {
    return res.data;
  });

const disableSendNotification = (params: string[]): Promise<any> =>
  ApiClient.post(DISABLE_SEND_NOTI, params).then((res) => {
    return res.data;
  });

const enableSendNotificationAll = (params: StoreMasterParams): Promise<any> =>
  ApiClient.post(ENABLE_SEND_NOTI_ALL, params, { timeout: 1800000 }).then((res) => {
    return res.data;
  });

const disableSendNotificationAll = (params: StoreMasterParams): Promise<any> =>
  ApiClient.post(DISABLE_SEND_NOTI_ALL, params, { timeout: 1800000 }).then((res) => {
    return res.data;
  });

const downloadStoreMasterFile = (param: StoreMasterParams): Promise<any> =>
  ApiClient.post(DOWNLOAD_STORE_MASTER, param, {
    responseType: "blob",
  }).then((res) => res);

const toggleCheckingGeofence = (storeNumber: string, enabled: boolean): Promise<any> =>
  ApiClient.put(TOGGLE_CHECKING_GEOFENCE.replace(":store_number", storeNumber), { enabled: enabled }).then(
    (res) => {
      return res.data;
    },
  );

const isloadingTable = new BehaviorSubject<boolean>(false);
const signalNotiFail = new BehaviorSubject<boolean>(false);
const signalResetForm = new BehaviorSubject<boolean>(false);
const storeUpdated = new BehaviorSubject<StoreType | null>(null);

const storeMasterService = {
  searchStoreMaster,
  uploadStoreMaster,
  enableSendNotification,
  disableSendNotification,
  enableSendNotificationAll,
  disableSendNotificationAll,
  downloadStoreMasterFile,
  toggleCheckingGeofence,
  isloadingTable,
  signalNotiFail,
  signalResetForm,
  storeUpdated,
};

export default storeMasterService;
